import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import { Typography, Image, Tabs, Anchor, Collapse, Table} from 'antd';
import { ImageContainer, FakeLink} from "../styles/shared_components";
import { useLocation } from "react-router-dom";
const { Title, Paragraph, Link } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const Container = styled.div`
  width: 900px;  // Set a fixed width for the text container
  margin: 0 auto;  // Center the container horizontally
  padding: 20px;
  text-align: justify;  // Justify the text

  @media (max-width: 768px) {
    width: 100%;  // Allow full width on smaller screens
    padding: 10px;  // Adjust padding for smaller screens
  }
  //margin-left: 10%; // Basline was 5%, changed after iftah's request
  //margin-right: 10%; // Basline was 5%, changed after iftah's request

`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  
  .ant-tabs-nav {
    display: flex;
    justify-content: center;
    width: 100%;
    position: sticky;
    top: 53.5px;
    z-index: 101;
    background-color: ${({ theme }) => theme.cont}; // Adjust as needed
  }

  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px; // Increases the font size
    font-weight: bold; // Makes the text bold
    font-family: 'Montserrat', sans-serif; // applying Montserrat font to keep the font consistent
    // font-family: 'Merriweather', serif; // Not Applying Merriweather in order to keep the font consistent (Segoe UI is the default font for Ant Design)
    color: #483C32; // Sets a specific color for the tab text
  }
  
  .ant-tabs-tab:hover {
    color: #837970; // Light Taupe for hover effect
    cursor: pointer;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    color: #302822; // Dark Taupe for active tabs
  }
`;

const GlobalStyle = styled.div`
  body {
    font-family: 'Calibri', sans-serif;
  }
`;

const AnchorStyle = styled(Anchor)`
  background-color: ${({ theme }) => theme.cont}; // Adjust as needed
  // centering and bolding the anchor titles
  .ant-anchor-link-title {
    font-weight: bold;
  }
`;

const TaskLink = styled(Link)`
  color: #1890ff;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const BigBulletsParagraph = styled(Paragraph)`
  text-indent: -15px;
  padding-left: 40px;
  font-size: 19px;
`;

const SmallBulletsParagraph = styled(Paragraph)`
  text-indent: -10px;
  padding-left: 40px;
  font-size: 17px;
`;


const OverviewContent = ({handleLinkClick}) => {
  return (
    <>
          <Title level={1} style={{textAlign: 'center', fontSize: '45px' }}>
          <strong>The <i>qIAT Portal</i>: Implicit Assessment Using Standard Questionnaires</strong>
          </Title>
          <br />
          <Paragraph>
          Welcome to the qIAT Portal, a user-friendly platform designed to facilitate the application of the latest version of the Questionnaire-Based Implicit Association Test (qIAT; <a href="https://www.tandfonline.com/doi/full/10.1080/00223891.2021.1957904?scroll=top&needAccess=true" target="_blank">Friedman et al., 2022)</a>.
          </Paragraph>
          <SmallBulletsParagraph>
          <b> • The qIAT is based on the IAT, but uses propositions (rather than single words) as target stimuli. It therefore enables the implicit assessment of psychological constructs measured by existing, validated self-report scales (see examples <Link onClick={() => handleLinkClick('6')} style={{ fontSize: '18px' }}>here</Link>).</b>
          </SmallBulletsParagraph>
          <SmallBulletsParagraph>
          <b> • Assessment in the qIAT is indirect; unlike self-reports, it relies solely on categorization speed, not on respondents' reflections on the connections between the content of the scale’s items and themselves. It is therefore less vulnerable to self-presentation strategies.</b>
          </SmallBulletsParagraph>
          <SmallBulletsParagraph>
          <b> • Using this portal, researchers can easily create the qIAT, integrate it into their studies, and quickly process and analyze its output.</b>
          </SmallBulletsParagraph>
          <br />
          <Paragraph>
          <big><strong><u>The qIAT</u></strong></big>
          </Paragraph>
          <Paragraph>
          <b>The qIAT combines the indirect assessment methodology of the IAT with the semantic complexity and validity of standard self-report questionnaires</b> (Friedman & Yovel, 2013; Currie et al, 2017; Friedman et al, 2021; Yovel et al, 2022; Friedman et al, 2022).
          </Paragraph>
          <br />
          <Paragraph>
          <center>
          <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture1%20for%20portal.png" alt="Landing Page" /> 
          <p style={{ marginTop: '0' }}><small>Example of a personality trial in the critical blocks of the qIAT</small></p>
          </center>
          </Paragraph>
          <br />
          <Paragraph>
          <center>
          <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture2%20for%20portal.png" alt="landing Page" />
          <p style={{ marginTop: '0' }}><small>Example of a true vs. false trial in the critical blocks of the qIAT</small></p>
          </center>
          </Paragraph>
          <br />
          <Paragraph>
          The task contains two types of propositional stimuli: 
          </Paragraph>
          <SmallBulletsParagraph>
          •	Target stimuli (e.g., personality questionnaire items), which need to be classified into the <b>generic categories</b> “Type 1” vs. “Type 2”.
          </SmallBulletsParagraph>
          <SmallBulletsParagraph>
          •	Ten fixed <b>self-related statements</b> (e.g., 'I’m sitting in front of a computer'), which need to be classified into the logical attribute categories “<b>True</b>” vs. “<b>False</b>”.    
          </SmallBulletsParagraph>
          <Paragraph>
          The two types of stimuli need to be classified interchangeably into their respective categories.
          </Paragraph>
          <Paragraph>
          <b>This double classification procedure closely resembles self-report questionnaires, which typically require respondents to indicate the truthfulness of statements as they relate to themselves.</b>
          </Paragraph>
          <br />
          <Paragraph>        
          <b>It takes about 5 minutes to complete the qIAT. There is also a brief version of the task, which takes about 3 minutes. A detailed description of the qIAT is presented <Link onClick={() => handleLinkClick('3')}>here</Link>.</b >
          </Paragraph>
          <br />
          <Paragraph>
          Following the <a href="https://iatgen.wordpress.com/" target="_blank">IATGen</a> website (see Carpenter et al., 2019), which focuses on the conventional IAT, the qIAT Portal automates and streamlines the process of creating and analyzing the reaction-time based qIAT.
          </Paragraph>
          <SmallBulletsParagraph>
          •	A specialized application enables the incorporation of the items of the selected target questionnaire into the task, and the software generates a qIAT ready for implementation into a specialized running software (e.g., Qualtrics, Pavlovia).    
          </SmallBulletsParagraph>
          <SmallBulletsParagraph>
          •	The task can then be embedded within additional aspects of the study (e.g., self-report questionnaires, other tasks).   
          </SmallBulletsParagraph>
          <SmallBulletsParagraph>
          •	The Portal also offers a comprehensive set of data processing and diagnostics tools. 
          </SmallBulletsParagraph>
          <br />
          <Paragraph>
          At present, the Portal enables the generation of qIAT tasks in three languages: English, Arabic, and Hebrew.
          </Paragraph>
          <Paragraph>
          <strong>Please note that the qIAT Portal is intended exclusively for academic research and should not be used for commercial purposes!</strong>
          </Paragraph>
          <br />
      </>
  );
};

const WhyqIATContent = ({ handleLinkClick }) => {
  return (
    <>
        <Paragraph>
        <big><strong>The indirect assessment procedure of the qIAT addresses several notable limitations of the conventional IAT:</strong></big>
        <br />
        <br />
      </Paragraph>
      <Paragraph>
      <strong><u>Propositional stimuli:</u></strong> Rather than single-word or pictorial stimuli constructed ad hoc for a specific study, the qIAT uses the same set of <strong>validated</strong> propositional stimuli used by parallel self-reports. <strong>By using semantically complex propositional stimuli, it is possible to indirectly assess a much broader range of specific and refined psychological constructs, typically measured only by self-report.</strong>
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>“Implicitness” of the task:</u></strong> Although the qIAT's assessment procedure is clearly indirect, its implicitness (e.g., awareness of the task’s true purpose) should be tested empirically (Kurdi et al., 2021). While this issue has hardly been addressed with the conventional IAT (Gawronski, 2019), two studies show that <strong>the vast majority of the participants were unaware of the qIAT’s actual purpose</strong> (assessing self-perceptions; Friedman et al., 2022, Studies 4a and 4b). Notably, this broad objective is clearly stated in most self-reports (e.g., 'Describe yourself as you generally are…'). 
      </Paragraph>
      <br />
      <Paragraph> 
      <strong><u>Absolute estimates:</u></strong> The conventional IAT is inherently relative (e.g., confounding Black-negative with White-positive associations), and absolute estimates are more appropriate in most research contexts (see O’Shea & Wiers, 2020). <strong>Such absolute estimates can easily be obtained through the propositional qIAT, by measuring, for example, the associative truth value of statements reflecting attitudes toward a particular social group</strong> (see, for example, <Link onClick={() => handleLinkClick('6')}>a qIAT</Link> which uses the Attitudes Towards Black scale).
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>Generic categories:</u></strong> Ad hoc construct-related classification labels for the target categories (e.g., conscientiousness vs. carelessness, openness vs. narrowmindedness) tend to be judgmental, and they may adversely affect the outcome (Mitchell et al., 2003; Nosek et al., 2007). Therefore, the current version of the qIAT utilizes universal, generic target categories that are not semantically related to the assessed construct (i.e., “Type 1” vs. “Type 2”; Friedman et al., 2022). <strong>This method eliminates the necessity to create artificial, ad hoc labels, removes problematic impacts this aspect of the task may have on the results, and strengthens the conceptual and methodological correspondence with the parallel explicit assessment - in which no labels are used.</strong>
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>A clear association:</u></strong> Unlike the conventional self-IAT, the qIAT is not based on the problematic and vague association between the self (vs. others) and the target construct. The nature of these “others” in the self-IAT may not be clear, and more importantly, the broad and ambiguous association measured by the IAT may reflect several different relationships between the self and the measured construct (e.g., “I am good” vs. “I wish I were good”; see Remue et al., 2013). In contrast, in the qIAT, the target is the measured construct, not the self, and the attribute represents self-related truth. Thus, <strong>the qIAT unambiguously measures the extent to which respondents associate high</strong> (e.g., <i>I feel the I’m a person of worth</i>)<strong> vs. low </strong>(e.g., <i>I certainly feel useless at times</i>) <strong>levels of the assessed construct (e.g., general self-esteem) with truth about themselves.</strong>
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>Improved Assessment Consistency:</u> The methodological and conceptual alignment with the parallel self-report is much stronger in the qIAT</strong> (which uses generic categories and the same set of items) compared to the IAT. Therefore, any observed explicit-implicit dissociations (i.e., the magnitude of correlation between these two assessment modes and patterns of relationships with other variables) must be attributed to other factors related to the direct vs. indirect modes of assessment.
      </Paragraph>
      </>
  );
};

const QIATDescriptionContent = ({ handleLinkClick }) => {
  return (
    <div>
      <br id="qIATDescriptionTab"/>
        <Paragraph>
        <strong><u>A detailed description of the current version of the qIAT</u></strong>
      </Paragraph>
      <Paragraph>
      The qIAT, which follows earlier versions of the IAT (Greenwald et al., 1998; Sartori et al., 2008), is a brief double-classification, reaction-time based task.
      </Paragraph>
      <br />
      <Paragraph>
        In each trial of the qIAT, a sentence is presented at the center of the screen, along with category labels at the top right and left corners. Participants need to classify the sentences into the correct category as quickly and as accurately as possible by pressing one of two designated response keys (see examples <Link onClick={() => handleLinkClick('6')}>here</Link>).
      </Paragraph>
      <br />
      <Paragraph>
        <strong>In Block 1 (20 trials), they are introduced to the classification of the self-related logical propositions</strong> (e.g., “I am participating in a study on the internet” vs. “I am climbing a steep mountain”), that need to be classified into True vs. False categories, respectively (see below). 
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture3%20for%20portal.png" alt="Landing Page" /> 
      <p style={{ marginTop: '0' }}><small>The true vs. false self-related stimuli (a standard set, used in all qIAT tasks)</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture4%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Example of a trial in Block 1</small></p>
      </center>
      </Paragraph>
      <br />
      {/* ... COMMENTSSSSSS ... */}
      <Paragraph>
        Participants are then introduced to the target personality or attitude items that need to be classified into the generic Type 1 and Type 2 categories, using the same pair of response keys. The allocation of the non-reversed (e.g., reflecting high self-esteem) vs. reversed (e.g., reflecting low self-esteem) target items to the generic categories is counterbalanced across participants. 
      </Paragraph>
      <br />
      <Paragraph>
      <center>  
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture5%20for%20portal.png" alt="Landing Page" /> 
      <p style={{ marginTop: '0' }}><small>Example of a set of validated questionnaire items that can be used in the qIAT (Rosenberg, 1965)

      </small></p>
      </center>
      </Paragraph>
      {/* ... COMMENT AND SPACE!!!!! ... */}
      <Paragraph>
        <br />
        <strong>Familiarizing participants with the classification process, which is based on these arbitrarily assigned and non-semantic categories, is done in two practice blocks. </strong>
      </Paragraph>
      <Paragraph>
        <strong>In Block 2 (20 trials), a hint in the form of a number (1 or 2) appears at the end of each item </strong>(e.g., “I wish I could have more respect for myself – 1”, an item that in this case needs to be classified into the category “Type 1”). 
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture6%20for%20portal%E2%81%A9.png" alt="landing Page" />
      </center>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture7%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Examples of trials in Block 2</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
        <strong>In Block 3 (40 trials), participants classify the same items again into the same two generic categories, but this time without any categorization hint. </strong>
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture8%20for%20portal%E2%81%A9.png" alt="landing Page" />
      </center>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture9%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>  Examples of trials in Block 3</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
      <strong>Block 4 (40 trials) is a critical double categorization task, in which participants perform the two categorization tasks interchangeably</strong> (e.g., Type 1 and False items using one key, and Type 2 and True items using the other key). 
      </Paragraph>   
      <br />  
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture10%20for%20portal%E2%81%A9.png" alt="landing Page" />
      </center>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture%2011%20for%20portal%E2%81%A9.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>  Examples of trials in Block 4</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph> 
       <strong>In Block 5 (40 trials) the generic Type 1 and Type 2 categories switch sides, and participants practice sorting the target items with the new configuration. </strong> 
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture12%20for%20portal%E2%81%A9.png" alt="landing Page" />
      </center>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture13%20for%20portal%E2%81%A9.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>  Examples of trials in Block 5</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
      In Block 6 (40 trials), participants perform the second critical double categorization task, now with the reverse positioning of the generic Type 1 and Type 2 categories (i.e., one key for Type 2 or False items, and the other key for Type 1 or True items).
      </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture14%20for%20portal.png" alt="landing Page" />
      </center>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='480px' height='275px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture15%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>  Examples of trials in Block 6</small></p>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
      Throughout the task, a red X appears when participants respond incorrectly, and the subsequent trial appears following a correct response. The order of the double-categorization blocks (Blocks 4 and 6) is counterbalanced across participants.
      </Paragraph>
      <br id="qIATDescription/briefqIAT"/>
      <br />
      <br />
      <br />
      <Paragraph style={{ backgroundColor: '#D3D3D3', padding: '8px' }}>
      <span style={{color:'Black'}}> 
      <big><strong><u>The Brief qIAT</u></strong></big>
      <br/>
      The Brief qIAT is identical to the task described above, except that each block (including all practice blocks) contains exactly half of the trials. In studies involving several tasks, this shortened version may be used to maximize participant cooperation and minimize fatigue. 
      <br />
      <br />
      The Brief qIAT has been the subject of a pilot study to determine its feasibility. We began by examining existing data from two unpublished studies in which the version of the qIAT described above was administered, in addition to the parallel self-report scales. We found that using only 20 (instead of 40) trials per critical block had only a minimal impact on the task's reliability and validity. Based on these findings, we created the Brief qIAT and tested it directly, first when measuring extraversion (<i>N</i>=273). The split-half reliability was <i>R<small>tt</small></i> = .87, the implicit-explicit correlation was <i>r</i> = .59, <i>p</i> &lt; .001. 
      <br />
      <br />
      In addition, we tested (<i>N</i>= 172) a Brief qIAT using the items of the Aggressive Humor Style scale (Martin et al, 2003). We chose this scale because it contains items that are particularly long and semantically complex (e.g., “Even if something is really funny to me, I will not laugh or joke about it if someone will be offended”). The corrected split-half reliability was <i>R<small>tt</small></i> = .83, and the implicit-explicit correlation was <i>r</i> = .40. Taken together, these findings are comparable to those previously observed using the full version of the qIAT (Friedman et al., 2022).
      </span>
      </Paragraph>
      <br />
      <Paragraph>
        <center>
          <iframe width="480" height="300" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p style={{ marginTop: '0' }}><small></small></p>
        </center>
      </Paragraph>  
    </ div>
  );
};

const TaskCreationContent = () => {
  return (
    <>
    <AnchorStyle
    affix={true}
    offsetTop={110}
    direction="horizontal"
    items={[
      { title: <span style={{ marginRight: '20px' }}>General Instructions</span>, href: '#taskCreations/generalInstructions' },
      { title: <span style={{ marginRight: '20px' }}>Qualtrics-Dependent Mode</span>, href: '#taskCreations/qualtricsMode' },
      { title: <span style={{ marginRight: '20px' }}>Multi-Platform Independent Mode</span>, href: '#taskCreations/multiPlatformMode' },
    ]}
     />
    <div>
      <br id="taskCreations/generalInstructions"/>
      <h2>General Instructions</h2>
      <Paragraph>
      <strong>Before constructing the qIAT, we recommend familiarizing yourself with the qIAT description.</strong>
      </Paragraph>
      <br />
      <Paragraph>
      The qIAT that uses the selected items of your target questionnaire can be quickly and easily customized in the portal. The portal will handle the assimilation of these items into the task’s code, and the formation of the task into the running software (Qualtrics, Pavlovia, etc). This process results in a usable qIAT task that can be incorporated within the study.
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>Target Items</u></strong>
      </Paragraph>
      <Paragraph>
      Ideally, the qIAT uses 10 target items (five non-reversed, five reversed). The task can also be based on 8 target items (four of each type) or 12 items (six of each type). Some questionnaires, such as Rosenberg’s Self Esteem Scale (RSES; Rosenberg, 1965), contain exactly the number and type of items that are suitable for use in the qIAT (i.e., ten items, 5 reversed and five non-reversed). However, this is not the case for most scales. 
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>Reversed and non-reversed items.</u></strong> The task must use an equal number of stimuli corresponding to both target categories. However, many self-reports do not contain enough reversed (or non-reversed) items, and many are completely one-dimensional. In order to deal with this issue, it is possible to create ad-hoc items specifically for the purpose of the qIAT’s indirect assessment. <strong>Each of these ad-hoc items needs to reflect the opposite meaning of <u>one specific</u> existing non-reversed or reversed item of the target questionnaire</strong> (e.g., “I feel uncomfortable around people” is a specifically created ad-hoc item, which is based on the original extraversion item “I feel comfortable around people”). 
      </Paragraph>
      <br />
      <Paragraph>
      In several studies, we tested and confirmed the psychometric adequacy of qIATs that included such specifically created ad-hoc items that are directly based on the original target items (Friedman et al., 2022, Studies 1a,1b, 3b, 3c). 
      </Paragraph>
      <Paragraph>
      For example, study 3b focused on the <i>Need for Closure</i> (NFC) scale (Webster & Kruglanski, 1994), which doesn’t contain any reversed items. Each of the five <strong>specifically created reversed ad-hoc reversed items</strong> used in the qIAT (e.g., <strong>"I find that living without a consistent routine enables me to enjoy life more";</strong> see table below, <strong>right column</strong>) was based directly on a specific, original non-reversed item of this scale (e.g., "I find that establishing a consistent routine enables me to enjoy life more"; left column). 
      </Paragraph>
      <br />
      <Paragraph>
      <strong>Note that the allocation of reversed and non-reversed items to the generic Type1/Type2 category labels in the qIAT is counterbalanced across participants.</strong>
      </Paragraph>
      <br />
      <br />
      <Paragraph>
      <strong><center>NFC original items (all non-reversed)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ad-hoc reversed items</center></strong>
      </Paragraph>
      <Paragraph>
      <center>
      <Image width='800px' height='200px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture16%20for%20portal.png" alt="landing Page" />
      <center><p style={{ marginTop: '0' }}><small>NFC original (left) and specifically created ad hoc items (right). Note that each ad hoc item is based directly on a specific original item.</small></p></center>
      </center>
      </Paragraph>
      <br />
      <Paragraph>
      <strong><u>Number of items</u>.</strong> The qIAT uses 8-12 (typically 10) target items. In case the original target questionnaire contains a larger number of items, the “best” items (i.e., the best indicators of the assessed construct) need to be selected for the qIAT. Preferably, this should be done based on clear, previously published psychometric criteria. For example, in the above example, the five original non-reversed NFC items used in the qIAT were selected because they had the highest loadings on the single factor extracted in a principal component analysis, performed by Roets & Van Hiel (2011) on the brief version of the NFC scale.
      </Paragraph>
      <br />
      <hr></hr>
      <br />
      <Paragraph>
        <strong><u>Creating a new task using the Portal</u></strong>
      </Paragraph>
      <Paragraph>
      The construction of the qIAT involves a straightforward procedure where the selected reversed and non-reversed target items are uploaded. These items will be automatically incorporated into the qIAT task, in which they will be randomly allocated across participants to the generic Type 1/Type 2 target category labels. 
      </Paragraph>
      <Paragraph>
      <strong>Note that the qIAT differs from conventional IATs in that it uses fixed target (i.e., Type 1/Type 2) and attribute (i.e., True/False) category labels, as well as fixed True and False self-related attribute items.</strong>
      </Paragraph>
      <Paragraph>
      Enter the task name into the designated "Task name" box. 
      </Paragraph>
      <Paragraph>
      <strong>As part of the task creation process, <u>you will be requested to select which platform you want to work with (Qualtrics or others)</u>, at the "Study Platform" box. Then, you will see the specific instructions for the platform you have selected.</strong>
      </Paragraph>
      <Paragraph>
      <strong>To upload the selected items, follow these steps:</strong>
      </Paragraph>
      <Paragraph>
      Upload the items for each category (reversed and non-reversed). The two categories must include the same number of items (4-6 in each). We recommend using 5 reversed and 5 non-reversed items. 
      </Paragraph>
      <Paragraph>
      To make sure that the sentences are correctly arranged, navigate to the "My Tasks" section and press the "Open" button beneath each title ("Reversed items" and "Non-Reversed items") of the specific task. In case you need to make changes, simply discard the task and repeat the above steps. 
      </Paragraph>
      <hr></hr>
      <br id="taskCreations/qualtricsMode"/>
      <h2>Qualtrics-Dependent Mode</h2>
      <Paragraph>
      <strong>Important:</strong> Make sure you select "Qualertics", in the "Study Platform" box, and follow the general instructions above, for creating a new task using the Portal.
      </Paragraph>
      <Paragraph>
      The qIAT is provided as a standalone web task that can be used immediately. However, it is possible to incorporate it into a broader study, such as alongside other questionnaires or testing methods, by integrating it into your Qualertics survey.   
      </Paragraph>
      <br></br>
      <Paragraph>
        <strong><u>Uploading the task to Qualtrics</u></strong>
      </Paragraph>
      <Paragraph>
          <b>A.</b> Enter the "Survey" in Qualtrics in which you wish to add the new qIAT task.
        </Paragraph>
        <Paragraph>
        <b>B.</b> It is recommended to add a new "Block" in the Qualtrics survey, although it is not mandatory.
        </Paragraph>
        <Paragraph>
        <b>C.</b> Within the chosen Qualertics "Block", add a new "Question", with a 'Text entry' type. <b>Important!</b> Please make a note of the "Question" name (e.g., 'qIAT'), as it will be essential for the qIAT task analysis.
        </Paragraph>
        <Paragraph>
        <b>D.</b> Set "Multiple Lines" as the 'Text type' attribute for the "Question".
        </Paragraph>
        <Paragraph>
        <b>E.</b> Ensure the "Question" is empty, containing no text or other elements.
        </Paragraph>
        <Paragraph>
        <b>F.</b> In the 'Edit question' sidebar, navigate to 'Question behavior' and click on 'Javascript'.
        </Paragraph>
        <Paragraph>
        <b>G.</b> Replace all existing text with the script of the task <strong>in the portal (Each task has its own script; see example)</strong>
        </Paragraph>
      <br />
      <Paragraph>
      <center>
      <Image width='500px' height='400px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture17%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Example of the standard task configuration script</small></p>
      </center>
      </Paragraph>
      <Paragraph>
     <strong>The survey is now ready!</strong>
     </Paragraph>
     <br />
     <hr></hr>
     <br />
     <Paragraph>
     <strong>Note: </strong>It is highly recommended to conduct a pre-launch test of the qIAT, to verify accurate data recording. To do that, utilize the <strong><u>survey preview</u></strong> feature on Qualtrics and complete the survey several times.
     </Paragraph>
     <br />
     <Paragraph>
     To retrieve the dataset, navigate to the survey in your Qualtrics account. Go to "Data & Analysis" {'>'} "Export & Import" {'>'} "Export Data" {'>'} "Use numeric values" {'>'} and click the "Downloads" button. This action will download a CSV dataset.
     </Paragraph>
     <br />
     <Paragraph>
     Navigate to the file. If the qIAT is functioning properly, the cell in the row which refers to each participant in the column labeled "qIAT (<i>specific question name in Qualtrics</i>)", should include the participant’s information regarding: “condition”, “group”, “latency”, “block”, “stimulus”, and “correct”, for participants who have completed the whole task (If you click on a specific participant’s cell, you can see all the information in the “fx line” above at the top of the spreadsheet; see example below).  
     </Paragraph>
     <br />
      <Paragraph>
      <center><Image width='300px' height='300px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture18%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Example of the “qIAT (specific question name in Qualtrics)” column with participants’ information</small></p></center>
      </Paragraph>
      <br />
    <Paragraph>
    <strong>You are now ready to run your survey!</strong>
    </Paragraph>
    <br />
    <Paragraph>
    <center><strong><u>Please note that the qIAT task should be completed only on PCs and laptops, not on phones or tablets!</u></strong></center>
    </Paragraph>
    <br />
    <Paragraph>
    In order to customize a survey on Qualtrics for PCs and laptops use only, follow the following instructions: 
    </Paragraph>
    <Paragraph>
    Go to the "survey flow" located on the left side of the "survey" page. Add a branch right at the start of the survey by adding a "condition", followed by selecting "question" and then "device type" and choosing "is mobile". Specify that the branch's continuation will lead to the "end of survey". To end the survey, incorporate a personalized message for participants. Choose "custom end of survey message" and type the specific message you wish to convey to the participants (e.g., "This survey is not compatible with mobile devices").
    </Paragraph>
    </div>
    <hr></hr><br id="taskCreations/multiPlatformMode"/>
    <div>
    <h2>Multi-Platform Independent Mode</h2>
    <Paragraph>
      This option is designed for users of platforms other than Qualtrics. Although it is compatible with Qualtrics, this method is more complex and may not be the best choice for that platform. Here, the qIAT is provided as a standalone web task that can be launched immediately. However, if you plan to incorporate it into a broader study—such as alongside other questionnaires or testing methods—you will need to manually integrate the task. The guide below will assist you in seamlessly connecting the qIAT to your study components.
    </Paragraph>
    <br />
    <h3><span style={{fontSize: '24px'}}>Overview:</span> <span style={{fontSize: '19px'}}>Study Setup Process</span></h3>
    <Paragraph><strong>Stage 1: Pre-Task Setup</strong> Before integrating the qIAT, you’ll handle the initial part of your study independently. This could involve any combination of recruiting participants, conducting preliminary surveys, or other research activities using your preferred platforms. This flexibility allows you to customize the lead-up to the qIAT according to your specific research needs.</Paragraph>
    <Paragraph><strong>Stage 2: Transition from Pre-Task to qIAT</strong> After your preliminary activities, you'll begin incorporating your qIAT. When your task is ready on our website, you’ll receive a unique URL to your qIAT. This URL is what you will share with your participants. To track their progress and responses effectively, you need to append a unique identifier to the URL for each participant. This is done with a URL parameter called ‘pid’. For instance, if your participant has an ID ‘12345’, they should be directed to the URL http://www.example.com?pid=12345. If a participant accesses the URL without a ‘pid’ parameter, their responses will be recorded under a default identifier, useful for preliminary testing.</Paragraph>
    <Paragraph><strong>Understanding URL Parameters</strong> URL parameters, often known as query strings or query parameters, are extensions to the base URL that provide a way to pass information between web pages and servers. They consist of key-value pairs, where each key is a unique identifier and the value provides specific data associated with that key. These parameters begin after a question mark (?) in the URL and are separated by ampersands (&) if multiple parameters are used.</Paragraph>
    <Paragraph>For example, in the URL <FakeLink>http://www.example.com?pid=12345</FakeLink>, pid is the key and 12345 is the value assigned to this key. This parameter tells the server that the current session is associated with the participant ID ‘12345’.</Paragraph>
    <Paragraph><strong>Stage 3: Conducting the qIAT</strong> Once participants are directed to the qIAT through the URL you provided, they can start the task immediately. The task runs autonomously, requiring no further input until completion.</Paragraph>
    <Paragraph><strong>Stage 4: Post-qIAT Transition</strong> Upon completion of the qIAT, you have options for next steps. You can either direct participants to additional activities or conclude the study. When setting up your qIAT, you can define a URL for redirecting participants afterward and choose the parameter name to pass along the participant’s ID. This exit parameter can differ from the entry ‘pid’ parameter, providing flexibility in how you manage and track participant flow.</Paragraph>
    <Paragraph><strong>Stage 5: Post-Task Activities</strong> The final stage of your study could involve further tasks or surveys, or simply returning participants to the recruitment platform. This stage is tailored by you to fit the goals of your study.</Paragraph>
    <Paragraph><strong>Importance of Consistent URL Parameters</strong> Keeping the URL parameter consistent from start to finish is crucial. It ensures that each participant's qIAT results can be accurately connected with any other data collected during the study. This is especially valuable for analyzing correlations between different measures, such as the relationship between qIAT results and survey answers.</Paragraph>
    <br />
    <h3>Independent Mode Task Setup: Complete How-To Guide</h3>
    <Paragraph><strong>Step 1: Creating a New Task on the qIAT Portal</strong></Paragraph>
    <Paragraph><b>1) </b>Navigate to the 'New Task' page. <br /><b>2)</b> Under 'Study Platform', select 'Other'. <br /><b>3)</b> For 'Completion Participant ID URL Parameter' (see below), enter the URL parameter name that will carry the participant's ID to subsequent stages of the study (e.g., a follow-up survey or recruitment platform). Leave this field blank if it is not needed. <br /><b>4)</b> In 'Completion Redirect URL' (see below), enter the URL where participants should be redirected after completing the qIAT task. If there is no follow-up, leave this field blank; participants will see the task completion page. <br /> <b>5) If you are uncertain about the 'Completion Participant ID URL Parameter' and 'Completion Redirect URL', leave the task inactive and modify these settings later.</b></Paragraph>
    <p><u>Example</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/portal_create_true.jpg"
    width={'400px'}
    />
    </ImageContainer>

    <Paragraph><strong>Step 2: Configuring Post-Task Components (e.g., Manipulation Check, Follow-Up Survey)</strong></Paragraph>
    <Paragraph><b>1) </b>Copy and enter the URL of your post-task component into the 'Completion Redirect URL' field during task creation to automatically redirect participants there after completing the qIAT task (as mentioned above). The URL of your post-task component is given to you by your post-task platform (e.g., Qualtrics, Pavlovia, etc.) as an entry point to the post-task components.<br /><b>2) </b>Configure your post-task component to record the 'Completion Participant ID URL Parameter.' You will define this parameter name when you create the task, and it must be accurately captured by the platform you are using for post-task activities. Some platforms will handle this automatically, while others may require manual configuration to capture this parameter by the designated name.</Paragraph>
    <p><u>Example (Pavlovia)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/post_component_corr.jpg"
    width={'400px'}
    />
    </ImageContainer>
    <Paragraph><strong>Step 3: Configuring Pre-Task Components (e.g., Demographic Survey)</strong></Paragraph>
    <Paragraph><b>1) </b>Ensure that the 'pid' parameter is consistently passed from pre-task components to the task <u>using the exact name 'pid'</u>. This maintains uniformity and ensures the parameter is correctly captured across all stages of the study.<br/ ><b>2) </b>To direct participants to your qIAT task after they complete the pre-task component, use the task URL as the completion URL on your survey platform (see examples from Pavlovia and QuestionPro below).   Alternatively, to start the qIAT task immediately upon participant recruitment, use the task URL on your recruitment platform (see example from Prolific below). <br /><b>The task URL can be found in the qIAT Portal under 'My Tasks' {">"} 'Uploading the Task'</b>.<br /><b>3) </b>Alternatively, manually open the qIAT task by entering The task URL in your browser when running in a controlled setting like a lab. Append the participant ID as a URL parameter (pid) for proper session tracking. <br />For example: <FakeLink>https://run.pavlovia.org/pcplab/lfrrsszrjqu72rp?pid=SUBJECT_IDENTIFIER</FakeLink> (you can replace SUBJECT_IDENTIFIER with whatever you want).<br /><b>4) </b>If you run the task URL without specifying a 'pid' URL parameter, the system will automatically assign a 'pid' value of 0. This helps easily differentiate between testing the task and executing it under normal conditions.</Paragraph>
    <p><u>Example (Pavlovia)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pre_pavlovia.jpg"
    width={'400px'}
    />
    </ImageContainer>
    <p><u>Example (QuestionPro)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/questionpro_example.png"
    width={'400px'}
    />
    </ImageContainer>
    <p><u>Example (Prolific)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pre_prolific.jpg"
    width={'400px'}
    />
    </ImageContainer>
    </div>
    <br></br>
    <br></br>
    <Paragraph>
    <strong><u>Please note that the qIAT task should be completed only on PCs and laptops, not on phones or tablets!</u></strong>
    </Paragraph>
    </>
  );
};
const AnalysisContent = () => {
  return (
    <div>
      <br />
    <Paragraph>
    <strong>Please note:</strong>
    </Paragraph>
    <Paragraph>
      1. On any platform (Qualtrics or others), you can modify the default data analysis parameters for a specific task in the "Advanced Settings" section under the "Data Analysis" tab.
    </Paragraph>
    <Paragraph>
      2. Due to server limitations, the portal can process files with up to 2,500 participants. If your dataset exceeds this limit, please use the provided R code to perform the analysis manually.
    </Paragraph>
    <Paragraph>
      3. It's essential that each participant gets a unique ID to avoid problems or confusion during the analysis.
    </Paragraph>
    <br></br>
    <Paragraph>
    <strong><u>Retrieving and processing the qIAT data</u></strong>
    </Paragraph>
    <Paragraph>
    <u><strong>In Qualtrics:</strong></u>
    </Paragraph>
    <Paragraph>
    After running your survey, retrieve the data file from Qualtrics by accessing the survey in your Qualtrics account. Navigate to "Data & Analysis" {'>'} "Export & Import" {'>'} "Export Data" {'>'} "Use numeric values" and click the "Downloads" button.
    </Paragraph>
    <Paragraph>
    <u>Cleaning the data file:</u>  
    </Paragraph>
    <Paragraph>
    After downloading the output file, open it and make sure that the cell in the row which refers to the first participant (or any other participant) in the column labeled "qIAT (specific question name in Qualtrics)", include participant’s information regarding: “condition”, “group”, “latency”, “block”, “stimulus”, and “correct”, for participants who have completed the whole task (If you click on a specific participant’s cell, you can see all the information in the “fx line” above at the top of the spreadsheet; see example below). Participants lacking this information should be removed. 
    </Paragraph>
    <br />
    <Paragraph>
      <center>
      <Image width='300px' height='300px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture18%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Example of the “qIAT (<i>specific question name in Qualtrics</i>)” column with participants’ information</small></p>
      </center>
      </Paragraph>
      <br />
    <Paragraph>
    Additionally, inspect the "Distribution Channel" column, and ensure it displays the word "anonymous" in the participant's’ cell (in the row which refers to the specific participant). Responses marked as "preview" should be eliminated, because they were recorded while previewing the task (see example below).
    </Paragraph>
    <br />
    <Paragraph>
      <center>
      <Image width='300px' height='300px' src="https://pcplab.sfo2.digitaloceanspaces.com/Avichai/picture_for_portal/%E2%80%8E%E2%81%A8picture19%20for%20portal.png" alt="landing Page" />
      <p style={{ marginTop: '0' }}><small>Example of the "Distribution Channel" column</small></p>
      </center>
      </Paragraph>
      <br />
    <Paragraph>
    Then, navigate back to the qIAT Portal, select the “Data Analysis” tab and choose the relevant task. 
    </Paragraph>
    <Paragraph>
    Identify the column labeled "qIAT (<i>specific question name in Qualertics</i>)" <u>in the downloaded file from Qualertics</u>. Copy this <u>column’s label</u> and insert it into the <strong>"Column Name For qIAT's Data" box of the relevant task <u>in the portal</u></strong>. Additionally, insert the label "ResponseId" into the <strong>"Column Name For Response ID" box of the relevant task <u>in the portal</u></strong>. Click the "Click to Upload File" button and upload the cleaned data file that was originally downloaded from Qualtrics.
    </Paragraph>
    <Paragraph>
    After selecting the relevant task, upload a file, and insert names for the 'qIAT's data' and 'Response ID' columns, press the 'Submit' button.    
    </Paragraph>
    <Paragraph>
    The software will analyze your data file, and the processing time may vary depending on the number of participants in the file and the current load on our servers. This can range from as little as a few seconds to several hours. When the analysis is done, you will receive an email with a file containing the analyzed task scores and raw data (see details below). This file will also be available through the "My Tasks" page. If you don't receive the email within a few hours, please try again.
    </Paragraph>
    <br></br>
    <hr></hr>
    <br></br>
    <Paragraph>
    <u><strong>In Other Platforms:</strong></u>
    </Paragraph>
    <Paragraph>
    <strong>Note</strong>: If you are using platforms other than Qualtrics (e.g., Pavlovia), there is no requirement to upload any files to the Portal.
    </Paragraph>
    <Paragraph>
    After running your qIAT, return to the qIAT Portal, click on the 'Data Analysis' tab, select the relevant task, and press the 'Submit' button.   
    </Paragraph>
    <Paragraph>
    The software will analyze your data file, and the processing time may vary depending on the number of participants in the file and the current load on our servers. This can range from as little as a few seconds to several hours. When the analysis is done, you will receive an email with a file containing the analyzed task scores and raw data (see details below). This file will also be available through the "My Tasks" page. If you don't receive the email within a few hours, please try again.
    </Paragraph>
    <Paragraph>
    <br></br>
    <hr></hr>
    <br></br>
    <strong><u>Data analysis parameters:</u></strong>
    </Paragraph>
    <Paragraph>
    In any platform (Qualtrics or others), if you click on "Advanced Settings" located at the bottom of the "Data Analysis" screen, you can customize the following parameters that are used to identify problematic responses and compute the D-score of your qIAT task.
    </Paragraph>
    <Paragraph>
    If you don’t make any changes, the parameters are set as follows (see details below): 
    </Paragraph>
    <Paragraph>
    <ul id="myList">
  <li>Too Fast Trial (msec) = 300</li>
  <li>Fast Trials Participant Exclusion (%) = 10</li>
  <li>Max Errors Participant Exclusion (%) = 30</li>
  <li>Max Latency Participant Exclusion (sec)= 30</li>
  <li>Slow Trials Exclusion (sec) = 10</li>
  <li>Fast Trials Exclusion (msec) = 400</li>
</ul>
    </Paragraph>
    <br />
    <Paragraph>
    <u>Too Fast Trial</u> - Defines the maximum response time in milliseconds for a trial to be labeled as 'prematurely quick'. Trials faster than this threshold are flagged, indicating potentially hasty responses. (Note the difference between this parameter and “Fast Trials Exclusion” below).
    </Paragraph>
    <Paragraph>
    <u>Fast Trials Participant Exclusion</u> - Specifies the threshold <i>percentage</i> of ’Too Fast Trials’, based on the above parameter. If a participant exceeds this percentage, it suggests a pattern of rapid and premature responses, leading to the exclusion of their data from the analyses.
    </Paragraph>
    <Paragraph>
    <u>Max Errors Participant Exclusion</u> - Percentage of maximum errors above which a participant's data is excluded.
    </Paragraph>
    <Paragraph>
    <u>Max Latency Participant Exclusion</u> - This represents the upper limit of acceptable response time in seconds for <i>any</i> trial. Trials exceeding this duration suggest that the participant have paused or taken a break during the task (which is typically completed online), leading to the exclusion of their data from the analysis.
    </Paragraph>
    <Paragraph>
    <u>Slow Trials Exclusion</u> - Maximum allowed response time in seconds for a specific trial to be included in the analysis.
    </Paragraph>
    <Paragraph>
    <u>Fast Trials Exclusion</u> - Minimum allowed response time in milliseconds for a specific trial to be included in the analysis.
    </Paragraph>
    <Paragraph>
    Comprehensive information regarding the resulting processed data can be found <a href="#processed data">here</a>. 
    </Paragraph>
    <br />
    <hr></hr>
    <br />
    <Paragraph>
    <strong><u>Scoring</u></strong>
    </Paragraph>
    <Paragraph>
    The scoring of the qIAT involves analyzing reaction times within the two critical blocks (i.e., blocks 4 and 6). The qIAT D score is based on Greenwald et al’s (2003) criteria (D2 variant), adapted to the 6-block structure of the task (i.e., a single critical block in each condition). Paralleling the self-reports, larger positive D scores reflect a stronger association between the assessed trait or attitude (e. g., extraversion) and the self-related <i>True</i> category. Hence, individuals higher on the assessed trait are expected to have larger positive D scores.
    </Paragraph>
    <Paragraph>The D-score is automatically calculated for each participant (based on the cleaned data) as follows:
    </Paragraph>
    <ol>
    <li>Calculate the mean latencies for each of the two critical blocks: <strong>mean_congruent</strong> and <strong>mean_incongruent</strong>. [Note that the order of these double-categorization blocks (Blocks 4 and 6) is randomly counterbalanced across participants.]</li>
    <li>Compute the difference between these means: <strong>Difference = mean_congruent - mean_incongruent</strong></li>
    <li>Calculate the standard deviation of all latencies across both critical blocks: <strong>SD</strong>.</li>
    <li>Compute the <i>D</i> score: <strong><i>D</i> = Difference/SD</strong></li>
    </ol>
    <br />
    <Paragraph>
    <u>Split half reliability calculation</u>
    </Paragraph>
    <Paragraph>
    The processed data analysis file also includes three types of D-scores that are based on certain halves of the task. These D-scores can be used in order to compute the task’s split-half reliability (odd-even, paired odd-paired even and random split; see below). 
    </Paragraph>
    <Paragraph>
    Using the common split half procedure (i.e., odd vs. even trials) may not be suitable in the IAT, because in the critical blocks the two classification tasks (i.e., Type 1 vs. Type 2, and True vs. False in the qIAT) alternate interchangeably (Greenwald, A.G., Brendl, M., Cai, H. et al., 2022). Thus, using this method, each of the two resulting D-scores will be based only on one classification task. The two other options ensure that each half includes a both classification tasks. 
    </Paragraph>
    <Paragraph>
    These steps can later be used in order to compute the internal consistency of the task:
    </Paragraph>
    <Paragraph>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• <i>r</i> = correlation between the D-scores that are based on two halves of the task (e.g., paired even and paired odd).
    </Paragraph>
    <div id="processed data"></div>
    <Paragraph>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•	Spearman Brown corrected split -half reliability: Rtt =2r/1+r
    </Paragraph>
    <br />
    <hr></hr>
    <br />
    <Paragraph>
    <strong><u>The analysis Excel file (participants sheet) you have downloaded from the portal contains the following columns. Here, each row refers to a specific participant:</u></strong>
    </Paragraph>
    <br />
    <Paragraph>
    <strong>ID</strong> – A distinct code assigned to each participant. For example in Qualtrics data file, the ID is included within the Response ID column. <u>Note:</u> It's essential that each participant will get a unique ID to avoid issues or confusion during the analysis.
    </Paragraph>
    <Paragraph>
    <strong>nTrials</strong> – Number of critical trials performed by this participant. In the standard qIAT, it should be 80 for all participants. In the brief qIAT, it should be 40 for all participants.
    </Paragraph>
    <Paragraph>
    <strong>maxLatency</strong> - Maximum response time (in msec) for this participant across all critical trials.
    </Paragraph>
    <Paragraph>
    <strong>percFast</strong> – Percentage of critical trials that were faster than the "Too Fast Trials" threshold.
    </Paragraph>
    <Paragraph>
    <strong>percError</strong> - Percentage of errors across the two critical blocks.
    </Paragraph>
    <Paragraph>
   <strong>Exclude</strong> - Should this participant’s data be excluded from the analysis based on the exclusion criteria (i.e., errors, fast trials; see above)? “True” = should be excluded.
    </Paragraph>
    <Paragraph>
    <strong>Condition</strong> - Category paired with "True" in block 4: 0 for non-reversed, 1 for reversed.
    </Paragraph>
    <Paragraph>
    <strong>n_block_4</strong> - Number of valid trials in block 4 (i.e., after excluding errors and “too fast” trials).
    </Paragraph>
    <Paragraph>
    <strong>mean_block_4</strong> - Mean response time (in msec) for all valid trials in block 4.
    </Paragraph>
    <Paragraph>
    <strong>variance_block_4</strong> - Variance in response time (in msec) for all valid trials in block 4.
    </Paragraph>
    <Paragraph>
    <strong>n_block_6</strong> - Number of valid trials in block 6.
    </Paragraph>
    <Paragraph>
    <strong>mean_block_6</strong> - Mean response time (in msec) for all valid trials in block 6.
    </Paragraph>
    <Paragraph>
    <strong>variance_block_6</strong> - Variance in response time (in msec) for all valid trials in block 6.
    </Paragraph>
    <Paragraph>
    <strong>pooled_sd</strong> - Pooled standard deviation across the two critical blocks, used to compute the D-score.
    </Paragraph>
    <Paragraph>
    <big><strong>dscore</strong> - Task D-Score.</big>
    </Paragraph>
    <br />
    <Paragraph>
    <u><strong>The following variables can be used to compute the task’s split-half reliability (see above):</strong></u>  
    </Paragraph>
    <br />
    <Paragraph>
    <strong>dscore_even</strong> - Participant's half-task D-Score for even trials in the critical blocks. D-Score is calculated based on RTs in even-numbered trials.
    </Paragraph>
    <Paragraph>
    <strong>dscore_odd</strong> - Participant's half-task D-Score for odd trials in the critical blocks. D-Score is calculated based on RTs in odd-numbered trials.
    </Paragraph>
    <Paragraph>
    <strong>dscore_paired_even</strong> - Participant's half-task D-Score for even paired trials in the critical blocks. Paired trials involve grouping consecutive trials into pairs (e.g., trials 1 & 2, 3 & 4). D-Score is calculated based on RTs in the even-numbered pairs of trials (i.e., trials 3&4, 7&8, etc.).
    </Paragraph>
    <Paragraph>
    <strong>dscore_paired_odd</strong> - Participant's half-task D-Score for odd paired trials in the critical blocks. D-Score is calculated based on RTs in the odd-numbered pairs of trials (i.e., trials 1&2, 5&6, etc.).
    </Paragraph>
    <Paragraph>
    <strong>dscore_random_half1</strong> – The trials in each critical block are randomly divided to “half1” and “half2”. D-Score  is calculated based on RTs in “half1” trials.
    </Paragraph>
    <Paragraph>
    <strong>dscore_random_half2</strong> – The trials in each critical block are randomly divided to “half1” and “half2”. D-Score is calculated based on RTs in “half2” trials.
    </Paragraph>
    <br />
    <hr></hr>
    <br />
    <Paragraph>
     <strong><u>The analysis Excel file also contains a detailed trials sheet</u>. It enables in-depth examinations of the task, but it is <u>typically not needed for subsequent analyses</u>.</strong>
     </Paragraph>
     <Paragraph>
     <strong><u>Here, each row refers to a <i>single trial</i> in each of the completed qIAT tasks:</u></strong>
    </Paragraph>
    <br />
    <Paragraph>
    <strong>Condition</strong> - Category paired with "True" in block 4 for this participant: 0 for non-reversed, 1 for reversed.
    </Paragraph>
    <Paragraph>
    <strong>Group</strong> – Trial stimulus’ category group (i.e., Reversed, Non-reversed, True or False). 
    </Paragraph>
    <Paragraph>
    <strong>Latency</strong> – Participant's response time in the trial (in msec).
    </Paragraph>
    <Paragraph>
    <strong>Block</strong> – Trial's block number.
    </Paragraph>
    <Paragraph>
    <strong>Stimulus</strong> - The index of the stimulus within its respective group (i.e., Reversed, Non-reversed, True or False). For example, in a task where each group contains 5 items, the value range is 0-4, depending on its position within its group.  
    </Paragraph>
    <Paragraph>
    <strong>Correct</strong> - 1 = correct response, 0 = incorrect response.
    </Paragraph>
    <Paragraph>
    <strong>Id</strong> – Participant's ID.
    </Paragraph>
    <Paragraph>
    <strong>isEven</strong> - Indicator for whether the trial is considered an even trial for even/odd D-scores. 1 = trial is considered an even trial, 0 = trial is considered an odd trial. 
    </Paragraph>
    <Paragraph>
    <strong>isPairedEven</strong> - Indicator for if the trial is considered a paired-even trial for paired-even/paired-odd D-scores. 1 = paired even, 0 = paired odd. 
    </Paragraph>
    <Paragraph>
    <strong>isHalf1</strong> - Indicator for whether the trial is considered a half1 trial for half1/half2 D-scores (i.e., True or False). True = trial is considered a half1 trial, False = trial is considered a half2 trial.
    </Paragraph>
    <Paragraph>
    <strong>trialN</strong> – The index (between 1-200) of a specific trial conducted for each participant, which indicates the serial trial number each participant has actually completed across the whole task (i.e., practice trials and critical trails).  
    </Paragraph>
    <Paragraph>
    <strong>trialNBlock</strong> – The index (between 1-40, or 1-20 in the first two practice blocks) of a particular trial within a specific block for each participant, which indicates the serial trial number each participant has actually completed within a block. 
    </Paragraph>
    <br />
    </ div>
  );
};


const ExamplesContent = ({ defaultOpenPanel, handleLinkClick }) => {
  const examples = [
    {
      id: 1,
      headline: 'Extraversion qIAT',
      link: 'https://run.pavlovia.org/pcplab/2ve3ulwme1c3vfri',
      reference: 'Goldberg, L. R., Johnson, J. A., Eber, H. W., Hogan, R., Ashton, M. C., Cloninger, C. R., & Gough, H. G. (2006). The international personality item pool and the future of public-domain personality measures. Journal of Research in Personality, 40(1), 84–96. https://doi.org/10.1016/J.JRP.2005.08.007',
      resultsLink: "https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/qIAT_Extraversio_Results.xlsx",
      nonReversedSentences: [
            "I am the life of the party",
            "I feel comfortable around people",
            "I start conversations",
            "I talk to a lot of different people at parties",
            "I don't mind being the center of attention"
        ],
      reversedSentences: [
            "I don't talk a lot",
            "I keep in the background",
            "I have little to say",
            "I don't like to draw attention to myself",
            "I am quiet around strangers"
        ]
    },
    {
      id: 2,
      headline: 'Brief Extraversion qIAT',
      link: 'https://run.pavlovia.org/pcplab/lqfjztq3uvtxyvxg',
      reference: 'Goldberg, L. R., Johnson, J. A., Eber, H. W., Hogan, R., Ashton, M. C., Cloninger, C. R., & Gough, H. G. (2006). The international personality item pool and the future of public-domain personality measures. Journal of Research in Personality, 40(1), 84–96. https://doi.org/10.1016/J.JRP.2005.08.007',
      resultsLink: 'https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pavlovia_pilot_qiat_ext_results.xlsx',
      nonReversedSentences: [
        "I am the life of the party",
        "I feel comfortable around people",
        "I start conversations",
        "I talk to a lot of different people at parties",
        "I don't mind being the center of attention"
      ],
      reversedSentences: [
        "I don't talk a lot",
        "I keep in the background",
        "I have little to say",
        "I don't like to draw attention to myself",
        "I am quiet around strangers"
      ]
    },
    {
      id: 3,
      headline: 'Aggressive Humor Style qIAT',
      link: 'https://run.pavlovia.org/pcplab/b3tyvshxoin7jplu',
      reference: 'Martin, R. A., Puhlik-Doris, P., Larsen, G., Gray, J., & Weir, K. (2003). Individual differences in uses of humor and their relation to psychological well-being: Development of the Humor Styles Questionnaire. Journal of Research in Personality, 37(1), 48–75. https://doi.org/10.1016/S0092-6566(02)00534-2',
      resultsLink: "",
      nonReversedSentences: [
            "If someone makes a mistake, I will often tease them about it",
            "When telling jokes or saying funny things, I am usually not very concerned about how other people are taking it",
            "Sometimes I think of something that is so funny that I can't stop myself from saying it, even if it is not appropriate for the situation",
            "If I don't like someone, I often use humor or teasing to put them down"
        ],
      reversedSentences: [
            "People are never offended or hurt by my sense of humor",
            "I do not like it when people use humor as a way of criticizing or putting someone down",
            "I never participate in laughing at others even if all my friends are doing it",
            "Even if something is really funny to me, I will not laugh or joke about it if someone will be offended"
        ],
    },
    {
      id: 4,
      headline: 'Self-Esteem (RSES) qIAT',
      link: 'https://run.pavlovia.org/pcplab/zawhhflprjsgjrwm',
      reference: 'Rosenberg, M. (1965). Society and the adolescent self-image. Princeton University Press.',
      resultsLink: "",
      nonReversedSentences: [
            "On the whole, I am satisfied with myself",
            "I feel that I have a number of good qualities",
            "I am able to do things as well as most other people",
            "I feel that I am a person of worth",
            "I take a positive attitude toward myself"
        ],
      reversedSentences: [
            "At times, I think I am no good at all",
            "I feel I do not have much to be proud of",
            "I certainly feel useless at times",
            "I wish I could have more respect for myself",
            "All in all, I am inclined to feel that I am a failure"
        ]
    },
    {
      id: 5,
      headline: 'Need for Closure qIAT',
      link: 'https://run.pavlovia.org/pcplab/fap59ipflav76kuq',
      reference: 'Roets, A., & Van Hiel, A. (2011). Item selection and validation of a brief, 15-item version of the Need for Closure Scale. Personality and individual differences, 50(1), 90-94.',
      resultsLink: "",
      nonReversedSentences: [
            "I do not enjoy having a clear and structured mode of life",
            "I like unpredictable situations",
            "I like to go into a situation without knowing what I can expect from it",
            "I find that well-ordered life with regular hours do",
            "I find that living without a consistent routine enables me to enjoy life more"
        ],
      reversedSentences: [
            "I enjoy having a clear and structured mode of life",
            "I dislike unpredictable situations",
            "I don't like to go into a situation without knowing what I can expect from it",
            "I find that a well-ordered life with regular hours suits my temperament",
            "I find that establishing a consistent routine enables me to enjoy life more"
        ]
    },
    {
      id: 6,
      headline: 'Attitudes Towards Blacks scale qIAT',
      link: 'https://run.pavlovia.org/pcplab/n6bmzpzmimwlfysu',
      reference: 'Brigham, J. C. (1993). College students’ racial attitudes. Journal of Applied Social Psychology, 23(23), 1933-1967.',
      resultsLink: "",
      nonReversedSentences: [
            "I favor open housing laws that allow more racial integration of neighborhoods",
            "If a Black person were put in charge of me, I would not mind taking advice and direction from him or her",
            "I get very upset when I hear a White person make a prejudicial remark about Black people",
            "If I had a chance to introduce Black visitors to my friends and neighbors, I would be pleased to do so",
            "I would not mind it at all if a Black family with about the same income and education as me moved in next door"
        ],
      reversedSentences: [
            "I would rather not have Black people live in the same apartment building I live in",
            "Black people are demanding too much too fast in their push for equal rights",
            "Generally, Black people are not as smart as White people",
            "It is likely that Black people will bring violence to neighborhoods when they move in",
            "Interracial marriage should be discouraged to avoid the “who-am-I?” confusion that the children feel"
        ]  
    },
    {
      id: 7,
      headline: 'Extraversion qIAT (Hebrew-Male)',
      link: 'https://run.pavlovia.org/pcplab/unmkh2ivljea7nbp',
      reference: 'Goldberg, L. R., Johnson, J. A., Eber, H. W., Hogan, R., Ashton, M. C., Cloninger, C. R., & Gough, H. G. (2006). The international personality item pool and the future of public-domain personality measures. Journal of Research in Personality, 40(1), 84–96. https://doi.org/10.1016/J.JRP.2005.08.007',
      resultsLink: "",
      nonReversedSentences: [
            "אני הרוח החיה במסיבות ובמפגשים",
            "לא אכפת לי להיות במרכז תשומת הלב",
            "אני מרגיש בנוח בסביבת אנשים",
            "אני מדבר עם הרבה אנשים שונים במסיבות ובמפגשים",
            "אני בדרך כלל פותח בשיחה עם אחרים"
        ],
      reversedSentences: [
            "אני לא מרבה לדבר",
            "אני שקט בחברת זרים",
            "אני נוטה להישאר מאחור ולא להתבלט",
            "אני לא אוהב למשוך תשומת לב לעצמי",
            "אין לי הרבה מה לומר"
        ]
    },
    {
      id: 8,
      headline: 'Extraversion qIAT (Hebrew-Female)',
      link: 'https://run.pavlovia.org/pcplab/7fk5lnqv8zoovp2m',
      reference: 'Goldberg, L. R., Johnson, J. A., Eber, H. W., Hogan, R., Ashton, M. C., Cloninger, C. R., & Gough, H. G. (2006). The international personality item pool and the future of public-domain personality measures. Journal of Research in Personality, 40(1), 84–96. https://doi.org/10.1016/J.JRP.2005.08.007',
      resultsLink: "",
      nonReversedSentences: [
            "אני הרוח החיה במסיבות ובמפגשים",
            "לא אכפת לי להיות במרכז תשומת הלב",
            "אני מדברת עם הרבה אנשים שונים במסיבות ובמפגשים",
            "אני מרגישה בנוח בסביבת אנשים",
            "אני בדרך כלל פותחת בשיחה עם אחרים"
      ],
      reversedSentences: [
            "אני לא מרבה לדבר",
            "אני שקטה בחברת זרים",
            "אני נוטה להישאר מאחור ולא להתבלט",
            "אני לא אוהבת למשוך תשומת לב לעצמי",
            "אין לי הרבה מה לומר"
      ]
    },
    {
      id: 9,
      headline: 'Extraversion qIAT (Arabic)',
      link: 'https://run.pavlovia.org/pcplab/d5zpufcyoldvryzr',
      reference: 'Goldberg, L. R., Johnson, J. A., Eber, H. W., Hogan, R., Ashton, M. C., Cloninger, C. R., & Gough, H. G. (2006). The international personality item pool and the future of public-domain personality measures. Journal of Research in Personality, 40(1), 84–96. https://doi.org/10.1016/J.JRP.2005.08.007',
      resultsLink: "",
      nonReversedSentences: [
            "أنا ممتع في الحفلات والمناسبات",
            "لا أمانع أن أكون مركز الاهتمام",
            "أشعر بالارتياح مع الآخرين\t",
            "أتحدث مع الكثير من الناس في الحفلات والمناسبات",
            "أبادر/أبدأ الحديث مع الآخرين"
      ],
      reversedSentences: [
            "لا أتكلم كثيرا",
            "أكون صامتا حول الغرباء",
            "لا أحب أن أكون موضع الإنتباه",
            "لا أحب لفت الأنظار إلى نفسي",
            "لدي القليل لأقـُوله (لدي كلام قليل)"
      ],
    }
  ];

  const itemsTableColumns = [
    {
      title: 'Non-Reversed Items',
      dataIndex: 'nonReversedSentences',
      render: (sentences) => sentences.map((sentence, index) => <div key={index}>{sentence}</div>)
    },
    {
      title: 'Reversed Items',
      dataIndex: 'reversedSentences',
      render: (sentences) => sentences.map((sentence, index) => <div key={index}>{sentence}</div>)
    }
  ];

  return (
    <div>
    <h2>Examples of qIAT Tasks</h2>
    <Collapse accordion defaultActiveKey={defaultOpenPanel}>
      {examples.map(example => (
        <Panel header={example.headline} key={example.id} id={`6_${example.id}`}>
          <Table
            columns={itemsTableColumns}
            dataSource={[example]}
            size="small"
            pagination={false}
            // bordered
          />
          {example.id === 5 && <Paragraph><b>Reversed items were ad-hoc created (see <Link onClick={() => handleLinkClick('4')}>Task Creation</Link>)</b></Paragraph>}
          <Title level={5}><TaskLink to={example.link} href={example.link} target="_blank">Click here to try the task</TaskLink></Title>
          <p>Reference for the target questionnaire{example.id >= 7 && example.id <= 9 ? " (original English version)" : ""}: 
            <br />
            {example.reference}
            </p>
        </Panel>
      ))}
    </Collapse>
    <br />
    <br />
    <h2>Examples of qIAT Output Files</h2>
    <Collapse accordion>
      {/* only shows panels where resultsLink is not ""*/}
      {examples.filter(example => example.resultsLink !== "").map(example => (
        <Panel header={example.headline} key={example.id} id={`6_${example.id}`}>
          <Title level={5}><TaskLink
          to={example.resultsLink}
          href={example.resultsLink}
          target="_blank"
          >
          Click here to download an example output file  
          </TaskLink></Title>
        </Panel>
      ))}

    </Collapse>

    </div>
  );
};

const ContactUsContent = () => {
  return (
    <>
    <br></br>
    <Paragraph>
        For any comments or questions, please contact us at PCPlab@mail.huji.ac.il
        </Paragraph>
        <br></br>
        <Paragraph>
        The qIAT Portal was developed by Roee Cohen-Shahar, Elad Zlotnick, Avichai Lavy, Rony Tzadok, Maoz Rosenfeld, and Iftah Yovel at the Personality, Cognition, and Psychopathology lab, Department of Psychology, The Hebrew University of Jerusalem.  
        </Paragraph>
     
      </>
  );
};

const ReferencesContent = () => {
  return (
    <div>
        <br />
    <Paragraph>
    Carpenter, T. P., Pogacar, R., Pullig, C., Kouril, M., Aguilar, S., LaBouff, J., Isenberg, N & Chakroff, A. (2019). Survey-software implicit association tests: A methodological and empirical analysis. <i>Behavior research methods</i>, 51, 2194-2208.
    </Paragraph>
    <br />
    <Paragraph>
    Currie, C. J., Katz, B. A., & Yovel, I. (2017). Explicit and implicit shame aversion predict symptoms of avoidant and borderline personality disorders. <i>Journal of research in personality</i>, 71, 13-16.
    </Paragraph>
    <br />
    <Paragraph>
    Friedman, A., Katz, B. A., Cohen, I. H., & Yovel, I. (2021). Expanding the scope of implicit personality assessment: an examination of the questionnaire-based implicit association test (qIAT). <i>Journal of Personality Assessment</i>, 103(3), 380-391.
    </Paragraph>
    <br />
    <Paragraph>
    Friedman, A., Katz, B. A., Elishevits, Y., & Yovel, I. (2022). Increasing the flexibility of implicit personality assessment: An examination of a universal assessment procedure of the self. <i>Journal of Personality Assessment</i>, 104(4), 532-547.
    </Paragraph>
    <br />
    <Paragraph>
    Gawronski, B. (2019). Six lessons for a cogent science of implicit bias and its criticism. <i>Perspectives on Psychological Science</i>, 14(4), 574-595.
    </Paragraph>
    <br />
    <Paragraph>
    Greenwald, A. G., McGhee, D. E., & Schwartz, J. L. K. (1998). Measuring individual differences in implicit cognition: The implicit association test. <i>Journal of Personality and Social Psychology</i>, 74(6), 1464–1480.
    </Paragraph>
    <br />
    <Paragraph>
    Greenwald, A. G., Nosek, B. A., & Banaji, M. R. (2003). Understanding and using the implicit association test: I. An improved scoring algorithm. <i>Journal of personality and social psychology</i>, 85(2), 197.
    </Paragraph>
    <br />
    <Paragraph>
    Kurdi, B., Ratliff, K. A., & Cunningham, W. A. (2021). Can the Implicit Association Test serve as a valid measure of automatic cognition? A response to Schimmack (2021). <i>Perspectives on Psychological Science</i>, 16(2), 422-434.
    </Paragraph>
    <br />
    <Paragraph>
    Martin, R. A., Puhlik-Doris, P., Larsen, G., Gray, J., & Weir, K. (2003). Individual differences in uses of humor and their relation to psychological well-being: Development of the Humor Styles Questionnaire. <i>Journal of research in personality</i>, 37(1), 48-75.
    </Paragraph>
    <br />
    <Paragraph>
    Mitchell, J. P., Nosek, B. A., & Banaji, M. R. (2003). Contextual variations in implicit evaluation. <i>Journal of Experimental Psychology: General</i>, 132(3), 455.
    </Paragraph>
    <br />
    <Paragraph>
    Nosek, B. A., Greenwald, A. G., & Banaji, M. R. (2007). The Implicit Association Test at age 7: A methodological and conceptual review. <i>Automatic processes in social thinking and behavior</i>, 4, 265-92.  
    </Paragraph>
    <br />
    <Paragraph>
    O'Shea, B. A., & Wiers, R. W. (2020). Moving beyond the relative assessment of implicit biases: Navigating the complexities of absolute measurement. <i>Social Cognition</i>, 38(Supplement), s187-s207. 
    </Paragraph>
    <br />
    <Paragraph>
    Remue, J., De Houwer, J., Barnes-Holmes, D., Vanderhasselt, M. A., & De Raedt, R. (2013). Self-esteem revisited: Performance on the implicit relational assessment procedure as a measure of self-versus ideal self-related cognitions in dysphoria. <i>Cognition & emotion</i>, 27(8), 1441-1449. 
    </Paragraph>
    <br />
    <Paragraph>
    Roets, A., & Van Hiel, A. (2011). Item selection and validation of a brief, 15-item version of the Need for Closure Scale. <i>Personality and individual differences</i>, 50(1), 90-94.
    </Paragraph>
    <br />
    <Paragraph>
    Rosenberg, M. (1965). Society and the adolescent self-image. <i>Princeton University Press</i>. 
    </Paragraph>
    <br />
    <Paragraph>
    Sartori, G., Agosta, S., Zogmaister, C., Ferrara, S. D., & Castiello, U. (2008). How to accurately detect autobiographical events. <i>Psychological Science</i>, 19(8), 772–780.
    </Paragraph>
    <br />
    <Paragraph>
    Webster, D. M., & Kruglanski, A. W. (1994). Individual differences in need for cognitive closure. <i>Journal of personality and social psychology</i>, 67(6), 1049.
    </Paragraph>
    <br />
    <Paragraph>
    Yovel, I., & Friedman, A. (2013). Bridging the gap between explicit and implicit measurement of personality: The questionnaire-based implicit association test. <i>Personality and Individual Differences</i>, 54(1), 76-80.  
    </Paragraph>
    <br />
    <Paragraph>
    Yovel, I., Aviram, G., Kahana, N., & Katz, B. A. (2022). Testing a new indirect measure of general self‐worth: The Self‐esteem Questionnaire‐based Implicit Association Test. <i>British Journal of Social Psychology</i>, 61(1), 100-120.
    </Paragraph>
    </div>
  );
};



// Example LandingPage Component
const LandingPage = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('1');
  const [defaultOpenPanel, setDefaultOpenPanel] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    if (hash == "#qIATDescription/briefqIAT") {
      setActiveKey('3');
    } else if (hash.includes('taskCreations')) {
      setActiveKey('4');
    } else if (hash.includes('exampleContent')) {
      setActiveKey('6');
      setDefaultOpenPanel('6'); // Set the default open panel for the Examples tab
    }
  }, [location.hash]);

  useEffect(() => {
    const scrollToElement = () => {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    // Check if the DOM has updated and then scroll to the element
    if (
      (activeKey === '3' && location.hash === '#qIATDescription/briefqIAT') ||
      (activeKey === '4' && location.hash.includes('taskCreations')) ||
      (activeKey === '6' && location.hash.includes('exampleContent'))
    ) {
      setTimeout(scrollToElement, 100); // slight delay to allow tab content to render
    }
  }, [activeKey, location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeKey]);

  const handleLinkClick = (tabKey, panelId) => {
    setActiveKey(tabKey);
    setTimeout(() => {
      const element = document.getElementById(panelId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };
  
  

  return (
    <>
    <GlobalStyle />
    {/* <Container> */}
      <Title level={1} style={{ textAlign: 'center' }}></Title>
      <StyledTabs defaultActiveKey="1" onChange={setActiveKey} activeKey={activeKey}>
        <TabPane tab="Overview" key="1">
          <Container>
          <OverviewContent handleLinkClick={handleLinkClick} />
          </Container>
        </TabPane>
        <TabPane tab={<span style={{ fontStyle: 'italic', fontWeight: '900' }}>Why qIAT?</span>} key="2">
        <Container>
          <WhyqIATContent handleLinkClick={handleLinkClick} />
        </Container>
        </TabPane>
        <TabPane tab="qIAT Description" key="3">
          <Container>
          <QIATDescriptionContent handleLinkClick={handleLinkClick} />
          </Container>
        </TabPane>
        <TabPane tab="Examples" key="6">
          <Container>
            <ExamplesContent defaultOpenPanel={defaultOpenPanel} handleLinkClick={handleLinkClick}/>
          </Container>
        </TabPane>
        <TabPane tab="Task Creation" key="4">
        <Container>
          {TaskCreationContent()}
          </Container>
        </TabPane>
        <TabPane tab="Analysis" key="5">
          <Container>
          {AnalysisContent()}
          </Container>
        </TabPane>
        <TabPane tab="Contact Us" key="7">
          <Container>
          {ContactUsContent()}
          </Container>
        </TabPane>
        <TabPane tab="References" key="8">
          <Container>
          {ReferencesContent()}
          </Container>
        </TabPane>

      </StyledTabs>
    {/* </Container> */}
    </>
  );
};

export default LandingPage;
